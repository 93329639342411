import React from 'react'
import Layout from '../components/Layout/Layout'

export default () => {
  return (
    <Layout title="College Pulse Terms">
      <main className="container">
        <article className="content has-padding-5">
          <h1>TERMS OF SERVICE</h1>
          <p>
            The following Terms of Service (&ldquo;<strong>Terms</strong>&rdquo;) explain how you
            may access and use our mobile application (&ldquo;App&rdquo;) and our website:{' '}
            <u>https://collegepulse.com</u>, and all other domains and subdomains related to the
            company, as well as any other of our services that are available through our App or our
            site (collectively, the &ldquo;<strong>Services</strong>&rdquo;). Your access to and use
            of the Services are conditioned on your acceptance of and compliance with these Terms.
            By clicking the &lsquo;Accept&rsquo; box or by accessing or using the Services, you
            agree to be bound by these Terms. If you are enrolling on behalf of a Poll Creator that
            is an organization, by accepting these Terms you are doing so on behalf of such
            organization and are representing that you have the authority to do so. In such event,
            references to &lsquo;you&rsquo; and &lsquo;your&rsquo; herein shall be to the
            organization.
          </p>
          <ol>
            <li>
              <strong>Your Content</strong>
            </li>
          </ol>
          <p>
            <strong>
              <em>(a) Privacy.</em>
            </strong>{' '}
            By using our App, our Site and the Services, you will be submitting your personal data,
            as well as personal data of others directly or through third parties to College Pulse
            (your &ldquo;<strong>Content</strong>&rdquo;). College Pulse is committed to protecting
            your Content and treating it appropriately. You agree that College Pulse may use and
            share your Content in accordance with College Pulse&rsquo;s Privacy Policy and we agree
            to abide by our Privacy Policy. By agreeing to these Terms, you acknowledge that you
            have reviewed our <u>Privacy Policy</u> and agree to its terms.
          </p>
          <p>
            <strong>
              <em>(b) Confidentiality</em>
            </strong>
            <strong>.</strong> College Pulse will treat your Content as confidential information and
            only use and disclose it in accordance with these Terms (including our Privacy Policy).
            However, your Content is not regarded as confidential information if such Content: (a)
            is or becomes public (other than through breach of these Terms by College Pulse); (b)
            was lawfully known to College Pulse before receiving it from you; (c) is received by
            College Pulse from a third party without knowledge of breach of any obligation owed to
            you; or (d) was independently developed by College Pulse without reference to your
            Content. College Pulse may disclose your Content when required by law or legal process,
            but only after College Pulse, if permitted by law, uses commercially reasonable efforts
            to notify you to give you the opportunity to challenge the requirement to disclose.
          </p>
          <p>
            <strong>
              <em>(c) Security</em>
            </strong>
            <strong>.</strong> College Pulse will store and process your Content in a manner
            consistent with industry security standards. College Pulse has implemented appropriate
            technical, organizational, and administrative systems, policies, and procedures designed
            to help ensure the security, integrity, and confidentiality of your Content and to
            mitigate the risk of unauthorized access to or use of your Content. By agreeing to these
            Terms, you acknowledge that you have reviewed our <u>Security Policy</u> and agree to
            its terms.
          </p>
          <p>
            <strong>
              <em>(d) User Content.</em>
            </strong>{' '}
            The Services display content provided by others that is not owned by College Pulse. Such
            content is the sole responsibility of the entity that makes it available.
            Correspondingly, you are responsible for your own Content and you must ensure that you
            have all the rights and permissions needed to use that Content in connection with the
            Services. College Pulse is not responsible for any actions you take with respect to your
            Content, including sharing it publicly. Please do not use content from the Services
            unless you have first obtained the permission of its owner, or are otherwise authorized
            by law to do so.
          </p>
          <p>
            <strong>
              <em>(e) Content Review.</em>
            </strong>{' '}
            You acknowledge that, in order to ensure compliance with legal obligations, College
            Pulse may be required to review certain content submitted to the Services to determine
            whether it is illegal or whether it violates these Terms (such as when unlawful content
            is reported to us). We may also modify, prevent access to, delete, or refuse to display
            content that we believe violates the law or these Terms. However, College Pulse
            otherwise has no obligation to monitor or review any content submitted to the Services.
          </p>
          <p>
            <strong>
              <em>(f) Third Party Resources.</em>
            </strong>{' '}
            College Pulse may publish links in its Services to internet websites maintained by third
            parties. College Pulse does not represent that it has reviewed such third party websites
            and is not responsible for them or any content appearing on them. Trademarks displayed
            in conjunction with the Services are the property of their respective owners.
          </p>
          <p>
            <strong>
              <em>(g) License.</em>
            </strong>{' '}
            With respect to all Content, you grant College Pulse a royalty-free, perpetual, irrevocable,
            non-exclusive license (with the right to sublicense) to use, reproduce, modify, adapt, edit, 
            publish, translate, create derivative works from, exploit, perform and display such Content 
            (in whole or part) throughout the world and/or to incorporate it in other works in any form, 
            media or technology now known or later developed, for any purposes.
          </p>
          <ol start="2">
            <li>
              <strong>College Pulse IP</strong>
            </li>
          </ol>
          <p>
            Neither these Terms nor your use of the Services grants you ownership in the Services or
            the content you access through the Services (other than your Content). Except as
            expressly permitted by College Pulse&rsquo;s in a separate agreement with you, these
            Terms do not grant you any right to use College Pulse&rsquo;s trademarks or other brand
            elements.
          </p>
          <ol start="3">
            <li>
              <strong>Account Management</strong>
            </li>
          </ol>
          <p>
            <strong>
              <em>(a) Password Security.</em>
            </strong>{' '}
            If you have been issued an account by College Pulse in connection with your use of the
            Services, you are responsible for safeguarding your password and any other credentials
            used to access and login to that account. You, and not College Pulse, are responsible
            for any activity occurring in your account (other than activity that College Pulse is
            directly responsible for which is not performed in accordance with your instructions),
            whether or not you authorized that activity. If you become aware of any unauthorized
            access to your account, you should notify College Pulse immediately. Accounts may not be
            shared and may only be used by one individual per account.
          </p>
          <p>
            <strong>
              <em>(b) Keep Your Details Accurate.</em>
            </strong>{' '}
            College Pulse occasionally sends notices to the email address registered with your
            account. You must keep your email address and, where applicable, your contact details
            and payment details associated with your account current and accurate. Accounts are
            controlled by the entity whose email address is registered with the account.
          </p>
          <p>
            <strong>
              <em>(c) Account Inactivity.</em>
            </strong>{' '}
            College Pulse may terminate your account and delete any content contained in it if there
            is no account activity (such as a login event or payment) for over 12 months. However,
            we will attempt to warn you by email before terminating your account to provide you with
            an opportunity to log in to your account so that it remains active.
          </p>
          <ol start="4">
            <li>
              <strong> Poll Creators</strong>
            </li>
          </ol>
          <p>
            <strong>
              <em>
                Only if you are creating a survey, the following additional terms and conditions
                apply to you and are included within the &ldquo;Terms&rdquo;.
              </em>
            </strong>
          </p>
          <p>
            <strong>
              <em>(a) Fees for Services.</em>
            </strong>{' '}
            You agree to pay to College Pulse all fees for each Service you purchase or use, in
            accordance with the pricing and payment terms presented to you for that Service at the
            time of purchase. Fees paid by you are non-refundable, except as provided in these Terms
            or when required by law.
          </p>
          <p>
            <strong>
              <em>(b) Subscriptions.</em>
            </strong>{' '}
            From time to time College Pulse may offer a subscription for some of its Services
            (&ldquo;<strong>Subscription</strong>&rdquo;). If we do and you purchase a Subscription,
            the terms of payment will include such terms as payment in advance on a recurring,
            periodic basis and auto-renew. The Subscription terms will be explained to you at the
            time of your enrollment in a Subscription.
          </p>
          <p>
            <strong>
              <em>(c) Price Changes.</em>
            </strong>{' '}
            College Pulse may change the fees charged for the Services at any time, provided that,
            for Services billed on a Subscription basis, the change will become effective only at
            the end of the then-current billing cycle of your Subscription. College Pulse will
            provide you with reasonable prior written notice of any change in fees to give you an
            opportunity to cancel your Subscription before the change becomes effective.
          </p>
          <p>
            <strong>
              <em>(d) Taxes.</em>
            </strong>{' '}
            Unless otherwise stated, you are responsible for any taxes (other than College
            Pulse&rsquo;s income tax) or duties associated with the sale of the Services, including
            any related penalties or interest (collectively, &ldquo;<strong>Taxes</strong>&rdquo;).
            You will pay College Pulse for the Services without any reduction for Taxes. If College
            Pulse is obliged to collect or pay Taxes, the Taxes will be invoiced to you, unless you
            provide College Pulse with a valid tax exemption certificate authorized by the
            appropriate taxing authority or other documentation providing evidence that no tax
            should be charged.
          </p>
          <p>
            <strong>
              <em>(e) Your Failure to Pay</em>
            </strong>
            <em>.</em> You are responsible to pay for all Services you Purchase and College Pulse
            shall have all remedies available at law to pursue collection against you in the event
            you fail to pay in accordance with these Terms. Included among those remedies, and
            always subject to applicable laws, College Pulse reserves the right to suspend or
            terminate your Service, which may result in a loss of your data associated with that
            Service.
          </p>
          <p>
            <strong>
              <em>(f) Customer Lists.</em>
            </strong>{' '}
            College Pulse may identify you (by name and logo) as a College Pulse customer on College
            Pulse&rsquo;s website and on other promotional materials.
          </p>
          <ol start="5">
            <li>
              <strong>Copyright Claims (DCMA Notices).</strong>
            </li>
          </ol>
          <p>
            College Pulse Inc. responds to notices of alleged copyright infringement in accordance
            with the U.S. Digital Millennium Copyright Act (DMCA). If you believe that your work has
            been exploited in a way that constitutes copyright infringement, you may notify College
            Pulse&rsquo;s{' '}
            <a href="/cdn-cgi/l/email-protection#a1d5c4d3d3c4cfe1d1d4cdd2c4d2d4d3d7c4d88fc2cecc9ed2d4c3cbc4c2d59ce2ced1d8d3c8c6c9d5849391e8cfc7d3c8cfc6c4ccc4cfd5">
              customer service agent
            </a>{' '}
            for claims of copyright infringement. College Pulse further respects the intellectual
            property rights of others, and we expect our users to do the same. If you believe a
            College Pulse user is infringing upon your intellectual property rights, you may{' '}
            <a href="/cdn-cgi/l/email-protection#2357465151464d6353564f504650565155465a0d404c4e1c505641494640571e604c535a514a444b570611136a4d45514a4d44464e464d57">
              report it to our agent
            </a>
            . Claims of copyright infringement should follow the DMCA process outlined in these
            Terms, or any equivalent process available under local law.
          </p>
          <ol start="6">
            <li>
              <strong>User Requirements</strong>
            </li>
          </ol>
          <p>
            <strong>
              <em>(a) Legal Status.</em>
            </strong>{' '}
            If you are an individual, you may only use the Service if you have the power to form a
            contract with College Pulse. None of the Services for Poll Takers are intended for use
            by individuals unless they are enrolled as students at or otherwise affiliated with one
            of our operating universities. If you are not affiliated, you may not use the Services.
            If you are a Poll Creator, you do not necessarily need to be affiliated with an
            operating university to create a survey.
          </p>
          <ol start="7">
            <li>
              <strong>Acceptable Uses</strong>
            </li>
          </ol>
          <p>
            <strong>
              <em>(a) Legal Compliance.</em>
            </strong>{' '}
            You must use the Services in compliance with, and only as permitted by, applicable law
            and in accordance with the Terms.
          </p>
          <p>
            <strong>
              <em>(b) Your Responsibilities.</em>
            </strong>{' '}
            You are responsible for your conduct, Content, and communications with others while
            using the Services. You must comply with the following requirements when using the
            Services: (i) You may not purchase, use, or access the Services for the purpose of
            building a competitive product or service or for any other competitive purposes; (ii)
            You may not misuse our Services by interfering with their normal operation, or
            attempting to access them using a method other than through the interfaces and
            instructions that we provide; (iii) You may not circumvent or attempt to circumvent any
            limitations that College Pulse imposes on your account (such as by opening up a new
            account to conduct a survey that we have closed for a Terms violation); (iv) Unless
            authorized by College Pulse in writing, you may not probe, scan, or test the
            vulnerability of any College Pulse system or network; (v) Unless authorized by College
            Pulse in writing, you may not use any automated system or software to extract or scrape
            data from the websites or other interfaces through which we make our Services available;
            (vi) Unless permitted by applicable law, you may not deny others access to, or reverse
            engineer, the Services, or attempt to do so; (vii) You may not transmit any viruses,
            malware, or other types of malicious software, or links to such software, through the
            Services; (viii) You may not engage in abusive or excessive usage of the Services, which
            is usage significantly in excess of average usage patterns that adversely affects the
            speed, responsiveness, stability, availability, or functionality of the Services for
            other users. College Pulse will endeavor to notify you of any abusive or excessive usage
            to provide you with an opportunity to reduce such usage to a level acceptable to College
            Pulse; (ix) You may not use the Services to infringe the intellectual property rights of
            others, or to commit an unlawful activity; (x) Unless authorized by College Pulse in
            writing, you may not resell or lease the Services; (xi) If your use of the Services
            requires you to comply with industry-specific regulations applicable to such use, you
            will be solely responsible for such compliance, unless College Pulse has agreed with you
            otherwise. You may not use the Services in a way that would subject College Pulse to
            those industry-specific regulations without obtaining College Pulse&rsquo;s prior
            written agreement. For example, you may not use the Services to collect, protect, or
            otherwise handle &ldquo;protected health information&rdquo; (as defined in 45 C.F.R.
            &sect;160.103 under United States federal regulations) without entering into a separate
            business associate agreement with College Pulse that permits you to do so.
          </p>
          <ol start="8">
            <li>
              <strong>Suspension and Termination of Services</strong>
            </li>
          </ol>
          <p>
            College Pulse may, in its sole discretion, limit, suspend and/or terminate, your use and
            access to the Services and/or Site, at any time and from time to time, with or without
            notice, for any of the following reasons: (a) you have materially breached these Terms
            and failed to cure that breach within 10 days (or such shorter time period deemed
            appropriate by College Pulse under the circumstances, including no notice) after College
            Pulse has so notified you in writing; or (b) if you are a Poll Creator and you cease
            your business operations or become subject to insolvency proceedings and the proceedings
            are not dismissed within 90 days, or you fail to pay fees for 30 days past the due date.
            Additionally, College Pulse may limit or suspend the Services to you if you fail to
            comply with these Terms, or if you use the Services in a way that causes legal liability
            to us or disrupts others&rsquo; use of the Services. College Pulse may also suspend
            providing the Services to you if we are investigating suspected misconduct by you. If we
            limit, suspend, or terminate the Services you receive and you are a Poll Creator, we
            will endeavor to give you advance notice and an opportunity to export a copy of your
            Content from that Service. However, there may be time sensitive situations where College
            Pulse may decide that we need to take immediate action without notice. College Pulse
            will use commercially reasonable efforts to narrow the scope and duration of any
            limitation or suspension under this Section as is needed to resolve the issue that
            prompted such action. College Pulse has no obligation to retain your Content upon
            termination of the applicable Service.
          </p>
          <ol start="9">
            <li>
              <strong>Further Measures.</strong>
            </li>
          </ol>
          <p>
            If College Pulse stops providing the Services to you because you repeatedly or
            egregiously breach these Terms, College Pulse may take measures to prevent the further
            use of the Services by you, including blocking your IP address.
          </p>
          <ol start="10">
            <li>
              <strong>Changes and Updates</strong>
            </li>
          </ol>
          <p>
            <strong>
              <em>(a) Changes to Terms.</em>
            </strong>{' '}
            College Pulse may change its <u>Privacy Policy</u> and/or these Terms at any time for a
            variety of reasons, such as to reflect changes in applicable law or updates to Services,
            and to account for new Services or functionality. The most current versions will always
            be posted on the College Pulse{' '}
            <u>
              <a href="https://collegepulse.com">Site</a>
            </u>
            . Notice of amendments may also be posted upon your login to your account. Changes will
            be effective no sooner than the day they are publicly posted on our{' '}
            <u>
              <a href="https://collegepulse.com">Site</a>
            </u>
            . In order for certain changes to become effective, applicable law may require College
            Pulse to obtain your consent to such changes, or to provide you with sufficient advance
            notice of them. If you do not want to agree to any changes made to the terms for a
            Service, you should stop using that Service, because by continuing to use the Services
            you indicate your agreement to be bound by the updated terms whether or not you reviewed
            them or were made aware of them.
          </p>
          <p>
            <strong>
              <em>(b) Changes to Services.</em>
            </strong>{' '}
            College Pulse constantly changes and improves the Services. College Pulse may add,
            alter, or remove functionality from a Service at any time without prior notice. College
            Pulse may also limit, suspend, or discontinue a Service at its discretion. If College
            Pulse discontinues a Service, we will give you reasonable advance notice to provide you
            with an opportunity to export a copy of your Content from that Service. College Pulse
            may remove content from the Services at any time in our sole discretion, although we
            will endeavor to notify you before we do that if it materially impacts you and if
            practicable under the circumstances.
          </p>
          <ol start="11">
            <li>
              <strong>Disclaimers and Limitations of Liability</strong>
            </li>
          </ol>
          <p>
            <strong>
              <em>(a) Disclaimers.</em>
            </strong>{' '}
            While it is in College Pulse&rsquo;s interest to provide you with a great experience
            when using the Services, there are certain things we do not promise about them. We try
            to keep our online Services up, but they may be unavailable from time to time for
            various reasons. EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS AND TO THE EXTENT PERMITTED
            BY APPLICABLE LAW, THE SERVICES ARE PROVIDED &ldquo;AS IS&rdquo; AND COLLEGE PULSE DOES
            NOT MAKE WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, OR STATUTORY, INCLUDING THOSE OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OR ANY
            REPRESENTATIONS REGARDING AVAILABILITY, RELIABILITY, OR ACCURACY OF THE SERVICES.
          </p>
          <p>
            <strong>
              <em>(b) Exclusion of Certain Liability.</em>
            </strong>{' '}
            TO THE EXTENT PERMITTED BY APPLICABLE LAW, COLLEGE PULSE, ITS AFFILIATES, OFFICERS,
            EMPLOYEES, AGENTS, SUPPLIERS, AND LICENSORS WILL NOT BE LIABLE FOR (A) ANY INDIRECT,
            CONSEQUENTIAL, SPECIAL, INCIDENTAL, PUNITIVE, OR EXEMPLARY DAMAGES WHATSOEVER, OR (B)
            LOSS OF USE, DATA, BUSINESS, REVENUES, OR PROFITS (IN EACH CASE WHETHER DIRECT OR
            INDIRECT), ARISING OUT OF OR IN CONNECTION WITH THE SERVICES AND THESE TERMS, AND
            WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, EVEN IF
            College Pulse HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND EVEN IF A REMEDY
            FAILS OF ITS ESSENTIAL PURPOSE.
          </p>
          <p>
            <strong>
              <em>(c) Limitation of Liability.</em>
            </strong>{' '}
            TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE AGGREGATE LIABILITY OF EACH OF COLLEGE
            PULSE, ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS, AND LICENSORS ARISING OUT
            OF OR IN CONNECTION WITH THE SERVICES AND THESE TERMS WILL NOT EXCEED THE LESSER OF: (A)
            THE AMOUNTS PAID BY YOU TO COLLEGE PULSE FOR USE OF THE SERVICES AT ISSUE DURING THE 12
            MONTHS PRIOR TO THE EVENT GIVING RISE TO THE LIABILITY; AND (B) US$200.00.
          </p>
          <p>
            <strong>
              <em>(d) Poll Takers.</em>
            </strong>{' '}
            We acknowledge that the laws of certain jurisdictions provide legal rights to Poll
            Takers as consumers that may not be overridden by contract or waived by those consumers.
            If you are such a Poll Taker consumer, nothing in these Terms limits any of those
            consumer rights.
          </p>
          <p>
            <strong>
              <em>(e) Businesses.</em>
            </strong>{' '}
            If you are Poll Creator, you agree to indemnify and hold harmless College Pulse and its
            affiliates, principals, officers, agents and employees from all liabilities, damages and
            costs (including settlement costs and reasonable attorneys&rsquo; fees) arising out of a
            third party claim regarding or in connection with your or your Poll Takers&rsquo; use of
            the Services or breach of these Terms, to the extent that such liabilities, damages and
            costs were caused by you or your Poll Takers.
          </p>
          <ol start="12">
            <li>
              <strong>SMS Campaigns</strong>
            </li>
          </ol>
          <p>
            <strong>
              <em>(a) Services.</em>
            </strong>{' '}
            When you opt in to College Pulse SMS, you will receive text messages (SMS/MMS) to your
            mobile number. These messages will include only incentivized/paid surveys!
          </p>
          <p>
            <strong>
              <em>(b) Opt Out.</em>
            </strong>{' '}
            You can opt out of this service at any time. Just text “STOP” to 888-608-2928, and we
            will send you an SMS reply to confirm that you have unsubscribed. After this, you will
            no longer receive SMS messages from us.
          </p>
          <p>
            <strong>
              <em>(c) Opt In.</em>
            </strong>{' '}
            You can opt in to our SMS at any time. Just text “START” to 888-608-2928, and we will
            start sending SMS messages about incentivized surveys.
          </p>
          <p>
            <strong>
              <em>(d) Support.</em>
            </strong>{' '}
            If you are experiencing any issues, please email us at mwall@collegepulse.com.
          </p>
          <p>
            <strong>
              <em>(e) Messages and Frequency.</em>
            </strong>{' '}
            Message and data rates may apply. You should expect to receive four messages per month.
          </p>
          <p>
            <strong>
              <em>(f) Data Rates.</em>
            </strong>{' '}
            If you have any questions about your text or data plan, please contact your wireless
            provider.
          </p>
          <p>
            <strong>
              <em>(g) Privacy.</em>
            </strong>{' '}
            If you have any questions regarding privacy, please read our{' '}
            <a style={{ color: 'blue' }} href="https://collegepulse.com/terms/#cp-privacy-policy">
              Privacy Policy
            </a>
            .
          </p>
          <p>
            <strong>
              <em>(h) Wireless Carrier Authorization.</em>
            </strong>{' '}
            You authorize your wireless carrier to use or disclose information about your account and your wireless device, 
            if available, to College Pulse or its service provider for the duration of your business relationship, 
            solely to help them identify you or your wireless device and to prevent fraud. See our Privacy Policy for how we treat your data.
          </p>
          <ol start="13">
            <li>
              <strong>Other Terms</strong>
            </li>
          </ol>
          <p>
            <strong>
              <em>(a) Contracting Entity.</em>
            </strong>{' '}
            Unless otherwise specified in relation to a particular Service, the Services are
            provided by, and you are contracting with, College Pulse LLC, a Delaware limited
            liability company. References to &ldquo;<strong>College Pulse</strong>&rdquo;, &ldquo;
            <strong>we</strong>&rdquo;, &ldquo;<strong>us</strong>&rdquo;, and &ldquo;
            <strong>our</strong>&rdquo; are references to College Pulse LLC.
          </p>
          <p>
            <strong>
              <em>(b) Assignment.</em>
            </strong>{' '}
            You may not assign these Terms without College Pulse&rsquo;s prior written consent,
            which may be withheld in College Pulse&rsquo;s sole discretion. College Pulse may assign
            these Terms at any time without notice to you.
          </p>
          <p>
            <strong>
              <em>(c) Entire Agreement.</em>
            </strong>{' '}
            These Terms, including the <u>Privacy Policy</u> which is incorporated herein by
            reference, constitute the entire agreement between you and College Pulse, and they
            supersede any other prior or contemporaneous agreements, terms and conditions, written
            or oral concerning its subject matter. Any terms and conditions appearing on a statement
            of work, purchase order or similar document issued by you do not apply to the Services,
            do not override or form a part of these Terms, and are void.
          </p>
          <p>
            <strong>
              <em>(d) Independent Contractors.</em>
            </strong>{' '}
            The relationship between you and College Pulse is that of independent contractors, and
            not legal partners, employees, or agents of each other. Neither of us has the right to
            legally bind the other.
          </p>
          <p>
            <strong>
              <em>(e) Interpretation.</em>
            </strong>{' '}
            The use of the terms &ldquo;includes&rdquo;, &ldquo;including&rdquo;, &ldquo;such
            as&rdquo;, and similar terms, will be deemed not to limit what else might be included.
          </p>
          <p>
            <strong>
              <em>(f) No Waiver.</em>
            </strong>{' '}
            A party&rsquo;s failure or delay to enforce a provision under these Terms is not a
            waiver of its right to do so later.
          </p>
          <p>
            <strong>
              <em>(g) Severability.</em>
            </strong>{' '}
            If any provision of these Terms is determined to be unenforceable by a court of
            competent jurisdiction, that provision will be severed and the remainder of terms will
            remain in full effect.
          </p>
          <p>
            <strong>
              <em>(h) Governing Law.</em>
            </strong>{' '}
            These Terms are governed by the laws of the State of Delaware (without regard to its
            conflict of laws provisions).
          </p>
          <p>
            <strong>
              <em>(i) Jurisdiction.</em>
            </strong>{' '}
            Except if prohibited by applicable law, each party submits to the exclusive jurisdiction
            of the state and federal courts located in the State of New Hampshire with respect to
            the subject matter hereof.
          </p>
          <p>
            <strong>
              <em>(j) Third Party Beneficiaries.</em>
            </strong>{' '}
            There are no third party beneficiaries to these Terms.
          </p>
          <p>
            <strong>
              <em>(k) Survival.</em>
            </strong>{' '}
            The following sections will survive the termination of these Terms: 1, 2, 4, 8, 11 and
            12.
          </p>
          <div style={{ marginTop: '3rem' }} id="cp-privacy-policy">
            <h1>PRIVACY POLICY</h1>
            <div>
              <hr />
            </div>
            <p>
              <em>Last Updated: July 19, 2019</em>
            </p>
            <p>
              College Pulse takes our users&rsquo; security and privacy concerns seriously. We
              strive to ensure that user data is kept secure, and that we collect only as much
              personal data as is required to make our users&rsquo; experience with College Pulse as
              efficient and satisfying as possible. We also aim to collect data in the most
              unobtrusive manner possible. We aim to be transparent about our security
              infrastructure and practices to help reassure you that your data is sufficiently
              protected. This Privacy Policy (&ldquo;<strong>Policy</strong>&rdquo;) describes how
              and when College Pulse collects, uses and shares your information when you use our
              Services.
            </p>
            <p>
              <strong>Main Privacy Points</strong>
            </p>
            <p>
              <em>Poll Creators:</em>
            </p>
            <ul>
              <li>
                <strong>Data disclosure: </strong>When a poll has been completed, you will be given
                the option to download the data. College Pulse may publicly disclose and share the
                data, unless other arrangements are made by you with College Pulse in advance and in
                writing. When a poll is active, you, along with the Poll Takers, will have the
                ability to see the live results of the poll in the &ldquo;Analyze&rdquo; section of
                the Site and mobile application. College Pulse may also use your data to
                cross-reference between polls. Poll Creators are prohibited from publicizing data
                containing personal identifying information (i.e., demographics) of Poll Takers,
                unless the number of people who match any published demographic exceeds ten.
              </li>
              <li>
                <strong>Poll data is stored on servers located in the United States.</strong>{' '}
                College Pulse will process your data on your behalf.
              </li>
              <li>
                <strong>We hold your data securely. </strong>College Pulse will store and process
                your Content in a manner consistent with industry security standards. For more
                information, please review our <u>Security Policy</u>
              </li>
            </ul>
            <p>
              <em>Poll Takers:</em>
            </p>
            <ul>
              <li>
                <strong>Your responses are confidential: </strong>Your email address will never be
                displayed publicly or privately in connection with your poll responses. Personal
                identifying information (i.e., demographics) will only be shared with fellow Poll
                Takers in the &ldquo;Analyze Section&rdquo; if the number of people who match the
                selected demographic exceeds five. This is to protect you from having your responses
                being attributed to you personally based on your demographic information. Personal
                identifying information may be collected and shared if you expressly give consent
                within a poll.
              </li>
              <li>
                <strong>Polls are administered by Poll Creators.</strong> We host the polls on our
                website and mobile application and collect the responses that you submit. The
                identity, or organization of the Poll Creator will be displayed publicly. Should you
                have any questions or concerns about the poll, you may contact the Poll Creator
                directly. College Pulse is not responsible for the content of any poll or your
                responses to it. The Poll Creator is usually the same person that invited you to
                take the poll.
              </li>
              <li>
                <strong>Data disclosure: </strong>College Pulse may publicly disclose and share poll
                data. When a poll is active, you, along with the student body, will have the ability
                to see the live results to the poll in the &ldquo;Analyze&rdquo; section of the Site
                and App. College Pulse may also use your data to cross-reference between polls.
              </li>
            </ul>
            <p>
              <strong>Information Collection and Use</strong>
            </p>
            <p>
              <strong>
                <em>What type of information we collect?</em>
              </strong>
            </p>
            <p>
              <em>Poll data:</em> We store your poll data (questions and responses) for you.
            </p>
            <p>
              <em>Basic Account Information:</em> When you create or reconfigure an account, you
              provide some personal information, including your email address, name, company, title,
              and role. For Poll Takers, you provide your email address, netID, personal demographic
              information, but we do not require you to provide your name.
            </p>
            <p>
              <em>Billing information:</em> If you make a payment to College Pulse, we require you
              to provide your billing details, such as a name, address, email address and financial
              information corresponding to your selected method of payment (e.g. a credit card
              number and expiration date or a bank account number). If you provide a billing
              address, we will regard that as the location of the account holder.
            </p>
            <p>
              <em>Social Media Sites: </em>You can like us on Facebook and promote us on other
              social media sites. We collect information about who has liked us or followed us.
              However, we don&rsquo;t associate your social media account with your College Pulse
              account. You may also share results through Facebook, Twitter, Instagram, and other
              social media accounts.
            </p>
            <p>
              <em>Usage data:</em> We collect usage data about you whenever you interact with our
              services. This may include which webpages you visit, rewards you claim, email
              notifications, what you click on, when you performed those actions, and so on.
              Additionally, like most websites today, our web servers keep log files that record
              data each time a device accesses those servers. The log files contain data about the
              nature of each access, including originating IP addresses, operating system versions,
              and timestamps.
            </p>
            <p>
              <em>Device data:</em> We collect data from the device and application you use to
              access our services, such as your IP address, operating system version, device type,
              system and performance information, and browser type. We may also infer your
              geographic location based on your IP address.
            </p>
            <p>
              <em>Other data you intentionally share:</em> We may collect your personal information
              or data if you submit it to us in other contexts. For example, if you provide us with
              a testimonial.
            </p>
            <p>
              <em>Geolocation Data: </em>We ask you to share your location information from your
              mobile device and/or computer. If you choose not to, the Services will not function.
              We may also determine location by using other data from your device, such as precise
              location information from GPS, information about wireless networks or cell towers near
              your mobile device, or your IP address. Location information is used to make relevant
              polls available to you and to study the effect of poll location data on responses. You
              can revoke your consent by changing the settings or preferences on your device and/or
              computer or by deleting College Pulse from your device. If you do not want College
              Pulse to use location information from your computer, you should also clear your
              browser history (where location information is stored).
            </p>
            <p>
              <em>Cookies: </em>Cookies are small data file identifiers that are transferred to your
              computer that allow us to recognize your browser and transfer information about you
              and your use of our Services. For example, cookies that we use tell us how many times
              you have visited one of our Sites. We use &ldquo;persistent&rdquo; cookies to save
              your login information for future logins to the Services. We also use &ldquo;session
              ID&rdquo; cookies to enable certain features of the Services, to better understand how
              you interact with the Services and to monitor aggregate usage by users and web traffic
              routing on the Services. Unlike persistent cookies, session ID cookies are deleted
              from your computer when you log off from a Service and close your browser. You can
              instruct your browser, by changing its settings, to stop accepting cookies or to
              prompt you before accepting a cookie from our Sites. If you do not accept cookies, you
              may not be able to use all portions of the Services or all functionality of the
              Services.
            </p>
            <p>
              <strong>
                <em>How do we use this information?</em>
              </strong>
            </p>
            <p>
              In general, we use the information we collect from and about you to provide and
              operate the Services, respond to your requests, and to provide customer service and
              technical support. We may also use information:
            </p>
            <ul>
              <li>
                To cross-reference data between polls with the goal of providing college campuses a
                comprehensive understanding of the student body.
              </li>
              <li>
                To tailor the polls that we may send or display to you, offer location
                customization, and send you push notifications and alerts.
              </li>
              <li>To enforce our Terms of Use.</li>
              <li>
                To allow the Poll Creator to contact the Poll Taker for informational purposes. We
                will only do this if the Poll Taker has consented with a poll to be contacted by the
                Poll Creator. For example, during a poll about Campus Sustainability, we will ask if
                you would like to give your name in order to get involved or receive information
                about campus groups related to the topic. We will send the Poll Creator a list
                containing the emails of all of the students who agreed to give their name. This
                information will not be distributed in the CSV data to the Poll Creator so as to not
                link your name with the rest of your responses.
              </li>
              <li>
                <strong>To contact you about your service or account. </strong>We occasionally send
                you communications of a transactional nature (e.g. service-related announcements,
                billing-related matters, changes to our services or policies, a welcome email when
                you first register). You can&rsquo;t opt out of these communications since they are
                required to provide our Services to you.
              </li>
              <li>
                To personalize and customize your experiences while using our Services, for example,
                by giving you better rewards and removing rewards you do not like.
              </li>
              <li>To send you notifications, both via smartphone and email.</li>
              <li>
                To better understand how users access and use our Services, both on an aggregated
                and individualized basis, in order to improve our Services and respond to user
                desires and preferences, and for other research and analytical purposes.
              </li>
              <li>
                To respond to your inquiries and fulfill your requests, such as to send you
                requested materials and newsletters, as well as information and materials regarding
                our products and services.
              </li>
              <li>
                To send administrative information to you, for example, information regarding the
                Sites and changes to our terms, conditions, and policies.
              </li>
              <li>
                To send you marketing communications, including via email and SMS in compliance with
                applicable laws and in accordance with your preferences, that we believe may be of
                interest to you.
              </li>
            </ul>
            <p>
              <strong>What Choices Do I Have?</strong>
            </p>
            <ul>
              <li>
                You can always opt not to disclose information. However, if you elect to do so, we
                will likely be limited in responding to your inquiry or providing services to you.
              </li>
              <li>
                You can opt-out of receiving marketing messages from College Pulse or our Affiliates
                by unsubscribing through the unsubscribe or opt-out link in an email, or by sending
                an email to{' '}
                <a
                  href="/cdn-cgi/l/email-protection"
                  data-cfemail="3343415a4552504a73505c5f5f56545643465f40561d505c5e1d"
                >
                  [email&nbsp;protected]
                </a>{' '}
                We will comply with your request(s) as soon as reasonably practicable. Please note
                that if you opt-out of receiving marketing-related emails from us, we may still send
                you important administrative messages.
              </li>
            </ul>
            <p>
              <strong>
                <em>We may disclose:</em>
              </strong>
            </p>
            <ul>
              <li>
                <strong>
                  Your information if there&rsquo;s a change in business ownership or structure.
                </strong>{' '}
                If ownership of all or substantially all of our business changes, or we undertake a
                corporate reorganization (including a merger or consolidation) or any other action
                or transfer between College Pulse entities, you expressly consent to College Pulse
                transferring your information to the new owner or successor entity so that they can
                continue providing our services. If required, College Pulse will notify the
                applicable data protection agency in each jurisdiction of such a transfer in
                accordance with the notification procedures under applicable data protection laws.
              </li>
            </ul>
            <ul>
              <li>
                <strong>Your information if required or permitted by law.</strong> We may disclose
                your information as required or permitted by law, or when we believe that disclosure
                is necessary to protect our rights, protect your safety or the safety of others,
                and/or to comply with a judicial proceeding, court order, subpoena, or other legal
                process served on us.
              </li>
            </ul>
            <ul>
              <li>
                <strong>Your information to Third Party Tools</strong>
                <strong>. </strong>We may use automated devices and applications, included those
                offered by third parties to evaluate usage of our Services. We also may use other
                analytic means to evaluate our Services. We use these tools to help us improve our
                Services, performance and user experiences. The information collected may include
                unique device identifiers, device manufacturer and operating system, IP address,
                browser type, session start/stop time, poll data, and user activity. To learn how
                Google Analytics uses your data from the Sites and how to opt out of collection of
                your information by Google Analytics via the Sites please visit{' '}
                <a href="http://www.google.com/analytics/learn/privacy.html">
                  http://www.google.com/analytics/learn/privacy.html
                </a>{' '}
                and{' '}
                <a href="https://tools.google.com/dlpage/gaoptout">
                  https://tools.google.com/dlpage/gaoptout
                </a>
                . To learn how Tableau keeps data secure, please visit{' '}
                <a href="http://www.tableau.com/privacy">http://www.tableau.com/privacy</a>.
              </li>
            </ul>
            <p>
              <strong>Contact Us</strong>
            </p>
            <p>
              Should you have any question or concern with any of the terms of our Terms of Use
              and/or Privacy Policy, or if you are aware of a suspected violation, please contact{' '}
              <a href="/cdn-cgi/l/email-protection#4b3f2e39392e250b3b3e27382e383e393d2e3265282426">
                [email&nbsp;protected]
              </a>{' '}
              to report it.
            </p>
          </div>
          <div style={{ marginTop: '3rem' }}>
            <h1>SECURITY STATEMENT</h1>
            <hr />
            <p>
              <em>Last updated: September 21, 2017</em>
            </p>
            <p>
              College administrations and students have entrusted College Pulse with their survey
              data, and we make our users&rsquo; security and privacy concerns our utmost priority.
              College Pulse uses state of the art security technologies to achieve this goal.
            </p>
            <p>
              <strong>User Security</strong>
            </p>
            <p>
              <em>Authentication</em>: College Pulse relies on the student providing their college
              email address or in some cases, SAML for authentication. SAML is the system trusted by
              universities and other large organizations across the country.
            </p>
            <p>
              <em>Privacy</em>: We have a privacy policy [link to privacy policy] that explains how
              we handle your data, including how we use your data, and who we share it with.
            </p>
            <p>
              <em>Data Residency</em>: All College Pulse data is stored on servers located in the
              United States.
            </p>
            <p>
              <strong>Physical Security </strong>All College Pulse information systems and
              infrastructure are hosted in Mongo Lab data centers. These data centers include all
              the necessary physical security controls, including 24&times;7 monitoring, cameras,
              visitor logs, and entry requirements.
            </p>
            <p>
              <strong>Software Development Practices </strong>Stack: We code in React JS and Node.js
              and run on Unix. Coding Practices: College Pulse developers use best practices and
              industry-standard secure coding guidelines. Deployment: We are consistently deploying
              improvements and updates, and can respond promptly to any bugs or vulnerabilities.
            </p>
            <p>
              <strong>Handling of Security Breaches </strong>No method of transmission over the
              Internet and no method of electronic storage is perfectly secure. College Pulse cannot
              guarantee absolute security. However, if we learn of a security breach, we will
              immediately use reasonable efforts to notify affected users so that they can take
              protective steps. Our breach notification procedures are consistent with our
              obligations under various state and federal laws and regulation, as well as any
              industry rules or standards that we adhere to. Notification procedures include
              providing email notices or posting a notice on our website if a breach occurs.
            </p>
            <p>
              <strong>Your Responsibilities </strong>Keeping your data secure also depends on you
              ensuring that you maintain the security of your account by using sufficiently
              complicated passwords and storing them safely. You should also ensure that you have
              sufficient security on your own systems, to keep any survey data you download to your
              own computer away from prying eyes. We offer TLS to secure the transmission of survey
              responses, but it is your responsibility to ensure that your surveys are configured to
              use that feature where appropriate.
            </p>
          </div>
          <div>
            <div>REFERRAL PROGRAM RULES</div>
            <p>
              These Referral Program Rules (&ldquo;Program Rules&rdquo;) govern your participation
              in the College Pulse Referral Program.
            </p>
            <p>
              <strong>Program Overview</strong>
            </p>
            <p>
              The College Pulse Referral Program (&ldquo;Referral Program&rdquo;) is offered by
              College Pulse, Inc. and its affiliates (together referred to as &ldquo;College
              Pulse&rdquo;) and provides Users who are interested in referring their friends
              (&ldquo;Referrers&rdquo;) the opportunity to refer eligible individuals to join the
              College Pulse community as new users. When you refer someone to College Pulse and they
              take a, you&rsquo;ll each 250 Pulse Point. You can use these points to buy reward
              offerings in the Pulse Reward Store. The Reward Criteria are set by College Pulse in
              its sole discretion and are subject to change at any time. These Program Rules apply
              to both referrers and referred Users, so please read carefully to understand your
              respective rights and obligations.
            </p>
            <p>
              <strong>Referrer Eligibility</strong>
            </p>
            <p>
              To be eligible to participate in the Referral Program as a referrer or referee, you
              must (a) be a legal resident of your country; (b) be the age of majority in your
              territory of residence; (c) maintain a College Pulse account in good standing; (d) be
              a current student at an Accredited Postsecondary Institution or Programs by the US
              Department of Education; (e) must have never previously created a College Pulse
              account. If you violate the Referral Program Rules or any applicable Terms of Service,
              College Pulse may suspend or terminate your ability to participate in the Referral
              Program.
            </p>
            <p>
              <strong>How to Refer New Users</strong>
            </p>
            <p>
              You may invite eligible individuals to become new Users by distributing your College
              Pulse Code directly and instructing them to manually input your College Pulse Code
              into the College Pulse App. A new User will not be able to apply your College Pulse
              Code if (i) they&rsquo;ve already applied another User&rsquo;s College Pulse Code or a
              different promotion code, (ii) they&rsquo;ve already received College Pulse credit
              from College Pulse or another source on their User account prior to entry of your
              College Pulse Code, or (iii) they take a College Pulse survey prior to entering your
              College Pulse Code in their Account. If a User does not enter your College Pulse Code
              you will not receive attribution for the referral and they will not be considered your
              Referred User.
            </p>
            <p>
              <strong>Restrictions</strong>
            </p>
            <p>
              In connection with your participation in the Referral Program, you expressly agree to
              the restrictions listed below. When distributing, promoting or communicating your
              Referral Code(s) you agree that:
            </p>
            <p>
              <strong>No spamming</strong>. You agree that you will not &ldquo;spam&rdquo; anyone
              with invitations to join the College Pulse community, and that you at all times will
              remain compliant with CAN-SPAM, the TCPA, and other applicable laws. The following
              specific activities are prohibited:
            </p>
            <ol>
              <li>Mass emailing, texting or messaging people you do not personally know;</li>
              <li>Giving your Referral Link to others to solicit referrals on your behalf.</li>
              <li>
                Use of automated systems or bots through any channel to distribute, post or respond
                to your Referral Code;
              </li>
              <li>
                Use of scripts, programed or automatic dialers to send invites or to communicate
                Referral Codes; and
              </li>
              <li>
                Posting referral codes on event or venue pages without express permission from the
                owner.
              </li>
            </ol>
            <p>
              <strong>No Misrepresentations</strong>. You agree that you will not attempt to mislead
              anyone in connection with the Referral Program, either by affirmative representation,
              implication, or omission. In particular, you agree that you will not:
            </p>
            <ol>
              <li>Impersonate any person or entity</li>
              <li>Create fake accounts, blogs, webpages, profiles, websites, links or messages</li>
              <li>
                Misrepresent your relationship with College Pulse or any other third party
                (Don&rsquo;t create content or communications that could have the effect of leading
                a consumer to believe that it is an official communication from College Pulse.)
              </li>
              <li>
                Suggest that an affiliation or partnership exists with a third party where none
                exists
              </li>
              <li>
                Don&rsquo;t make misrepresentations with respect to the characteristics or
                qualification requirements for any referral rewards. You acknowledge that College
                Pulse may change the characteristics or qualification requirements for referral
                rewards in its discretion, and therefore you shall not represent any fixed
                characteristics (e.g., amount of College Pulse credit, expiration date).
              </li>
            </ol>
            <p>
              <strong>Prohibited content</strong>. You agree that you will not use the College Pulse
              brand in connection with:
            </p>
            <ol>
              <li>Disparaging or defamatory content concerning College Pulse or third parties;</li>
              <li>
                Content which promotes racism, bigotry, hatred, discrimination or physical harm of
                any kind against any group or individual;
              </li>
              <li>Offensive, abusive, intimidating or harassing content</li>
              <li>Content that is sexually explicit, obscene and/or contains nudity</li>
              <li>Any political and/or religious statement;</li>
              <li>
                Content containing images or reference to drugs, alcohol, tobacco, weapons or
                firearms
              </li>
              <li>Content that violates someone else&rsquo;s privacy</li>
            </ol>
            <p>
              <strong>Other restrictions</strong>. You also agree that you will not:
            </p>
            <ol>
              <li>
                Specify the monetary amount of the referral code unless authorized by College Pulse
                in writing;
              </li>
              <li>
                Create websites, domains, URLs, social media handles or email addresses containing
                the word &ldquo;College Pulse&rdquo;;
              </li>
              <li>
                Use images of celebrities or other public or private figures without their written
                consent
              </li>
              <li>Give your Referral Link to others to solicit referrals on your behalf.</li>
              <li>
                Use someone else&rsquo;s brand name or intellectual property without their written
                consent (for example, referencing &ldquo;Superbowl&rdquo; or &ldquo;Coachella&rdquo;
                would be prohibited);
              </li>
              <li>
                Engage in phishing or attempting to obtain financial or other personal information
              </li>
              <li>Solicit passwords or personally identifiable information</li>
              <li>
                Sell your Referral Code. You agree that you will only distribute your College Pulse
                Code free of charge only for promotional purposes. You may not sell, trade, or
                barter your College Pulse Code under any circumstances. You may not pay or provide
                anything of value to an invited user beyond any referral credit or collateral
                supplied by College Pulse.
              </li>
              <li>
                Violate or infringe the rights of a third party. You will not create any College
                Pulse Code that infringes on the intellectual property rights of any third party.
                You will adhere to the License terms in any use of the College Pulse Marks.
              </li>
              <li>
                Advertise. You agree that you will not pay to advertise your College Pulse Code or
                the Referral Program, including via Google, Facebook, Twitter, Bing and Craigslist.
              </li>
              <li>
                Create printed materials other than those authorized by College Pulse (including but
                not limited to: VistaPrint)
              </li>
              <li>
                Post printed materials on public or private property without the express written
                consent.
              </li>
              <li>
                Engage in fraudulent activity. You agree that you and your referrals will not
                defraud or abuse (or attempt to defraud or abuse) College Pulse, the terms of the
                College Pulse Referral Program, or any invited users.
              </li>
            </ol>
            <p>
              If you violate any of these restrictions College Pulse may, in its discretion, remove
              your eligibility to participate in the Referral Program, and/or deny you any Referral
              Rewards earned in violation or suspected violation of these restrictions.
            </p>
          </div>
        </article>
      </main>
    </Layout>
  )
}
